<template>
  <div class="card" v-if="shipment.status">
    <b>
      N° Envío {{ shipment.delivery_number }} / OT
      {{ shipment.transport_order }}
    </b>
    <div id="status-image">
      <img :src="'layout/images/status/' + shipment.status + '.png'" />
    </div>
    <div id="right-buttons">
      <Button
        class="button"
        label="Ver etiqueta"
        icon="pi pi-eye"
        @click="printLabels([shipment.label])"
      />
      <Button class="button" label="Comprobante de entrega" icon="pi pi-book" />
      <Button
        class="button"
        label="Iniciar ticket"
        icon="pi pi-comments"
        @click="createTicketFromShipment(shipment.id, shipment.delivery_number)"
      />
    </div>
    <TabView>
      <TabPanel header="Historial de Estados">
        <DataTable :value="shipment.movements" showGridlines>
          <Column field="created_at" header="Fecha">
            <template #body="{ data }">
              <div class="p-d-flex p-ai-center">
                <span>{{ formatDateTime(data.created_at) }}</span>
              </div>
            </template>
          </Column>
          <Column field="description" header="Estado"></Column>
          <Column header="Comentario"></Column>
        </DataTable>
        <br />
        <p>
          Fecha compromiso: {{ formatDateTime(shipment.commitment_date) }} ({{
            transitDays(shipment)
          }}
          días de tránsito)
        </p>
      </TabPanel>
      <TabPanel header="Origen">
        <p>Empresa: {{ shipment.customer }}</p>
        <p>Bodega: {{ shipment.alias }}</p>
        <p>Comuna Bodega: {{ shipment.city }}</p>
        <p>
          Dirección Bodega: {{ shipment.address }} {{ shipment.address_number }}
        </p>
      </TabPanel>
      <TabPanel header="Destino">
        <p>Destinatario: {{ shipment.shipping_name }}</p>
        <p>Teléfono: {{ shipment.shipping_phone }}</p>
        <p>Correo: {{ shipment.shipping_email }}</p>
        <p>Tipo de Distribución: {{ shipment.distribution_type }}</p>
        <p>
          Dirección: {{ shipment.shipping_address_1 }} -
          {{ shipment.shipping_city }} - {{ shipment.region_name }}
        </p>
        <p>Observación: {{ shipment.shipping_observation }}</p>
      </TabPanel>
      <TabPanel header="Courier">
        <p>Courier: {{ shipment.courier }}</p>
        <p>N° Manifiesto: {{ shipment.manifest_id }}</p>
        <p>
          Manifiesto Confirmado:
          {{ shipment.manifest_confirmed_at ? "Sí" : "NO" }}
        </p>
        <p>Tipo de Servicio: {{ shipment.distribution_type }}</p>
      </TabPanel>
      <TabPanel header="Producto">
        <p>ID Venta: {{ shipment.order_id }}</p>
        <p>Alto: {{ shipment.size_data.height }}</p>
        <p>Ancho: {{ shipment.size_data.width }}</p>
        <p>Largo: {{ shipment.size_data.length }}</p>
        <p>Peso: {{ shipment.size_data.weight }}</p>
        <p>N° de Bultos: {{ shipment.packages }}</p>
        <p>Descripción: {{ shipment.description }}</p>
        <p>
          Valor del producto:
          {{ moneyFormatter(shipment.total) }}
        </p>
      </TabPanel>
    </TabView>
  </div>
</template>
<script setup>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { useRoute } from "vue-router";
import { getShipmentDetail } from "../../api/shipment";
import { ref } from "vue";
import { formatDateTime, transitDays, moneyFormatter } from "@/services/utils";
import { printLabels } from "@/services/shipments";
import { createTicketFromShipment } from "../../services/support";

const route = useRoute();
const shipment = ref({});

const loadShipmentData = async () => {
  const shipmentId = route.query.id;
  const response = await getShipmentDetail(shipmentId);
  shipment.value = response.data;
};

loadShipmentData();
</script>
<style scoped>
#status-image {
  text-align: center;
  border: 2px solid #cccccc;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}
#right-buttons {
  margin-top: 15px;
  text-align: right;
}
.button {
  margin-right: 5px;
}
</style>
